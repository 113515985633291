import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import { Link } from "gatsby"

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact us | Service Center</title>
        <meta
          name="description"
          content="Does your machine need a service? We&#x2019;d love to hear from you. Send us a message and we&#x2019;ll respond as soon as possible."
        />
      </Helmet>

      <Layout title="Contact">
        <section>
          <div className="container text-center">
            <div className="row">
              <div className="col-md-10 col-lg-8 mx-auto">
                <p className="lead">
                  Does your machine need a service? We’d love to hear from you.
                  Send us a message and we’ll respond as soon as possible.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-0 mt-n5 text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-6 col-lg-4 mt-5">
                <i className="fas mb-3 fa-2x fa-phone fa-rotate-90" />
                <h5>Phone Number</h5>
                <a href="tel:063706926">06 370 6926</a>
              </div>
              <div className="col-sm-6 col-lg-4 mt-5">
                <i className="fas mb-3 fa-2x fa-envelope" />
                <h5>Email</h5>
                <Link to="mailto:machinery.masterton@mitre10.co.nz">
                  machinery.masterton@mitre10.co.nz
                </Link>
              </div>
              <div className="col-sm-6 col-lg-4 mt-5">
                <i className="fas mb-3 fa-2x fa-clock" />
                <h5>Opening Hours</h5>
                <p>
                  <strong>Monday:</strong> 8:30am to 3:30pm
                  <br />
                  <strong>Tuesday – Friday:</strong> 8:30am to 5:00pm
                </p>
              </div>
            </div>
          </div>
        </section>

        <div id="fullWidthMap">
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6026.959902351868!2d175.6351554641027!3d-40.949063524382716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d4120d067d68d5b%3A0x53eca17b604cdf27!2sMitre%2010%20MEGA%20Masterton!5e0!3m2!1sen!2snz!4v1569914337728!5m2!1sen!2snz"
              width={600}
              height={450}
              frameBorder={0}
              style={{ border: 0 }}
              allowFullScreen
              title="map"
            />
          </div>
        </div>
      </Layout>
    </>
  )
}
